<template>
  <div class="card card-custom card-transparent">
    <div v-if="submitStatus === 'ERROR'">
      <b-alert show variant="danger" 
        ><i class="fas fa-exclamation-circle text-white mr-3"></i>

        {{$t('Alert')}}</b-alert
      >
    </div>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
        :dir="$t('clan')=='ar'?'rtl':'ltr'"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title mr-5">{{$t('New Queue')}}</div>
                  <div class="wizard-desc">{{$t('Add New Queue')}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                    
                  >
                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.queue.name.$error }"
                    >
                      <label class="form__label" :style="$t('clan')=='ar'?'float:right':''">{{$t('Queue Name')}}</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        :placeholder="$t('Queue Name')"
                        
                        :style="$t('clan')=='ar'?'text-align:right':''"
                        v-model="queue.name"
                        :class="{ 'form-input--error': $v.queue.name.$error }"
                        :state="$v.queue.name.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-xl-6 mb-7">
                        <label :style="$t('clan')=='ar'?'margin-left:280px':''">{{$t('Clients')}}</label>
                        <div
                          :class="{
                            'form-group--error': $v.queue.clients.$error,
                          }"
                        >
                          <vue-select
                            name="clients"
                            label="name"
                            :key="'clients-field'"
                            :options="createData.clients"
                            :closeOnSelect="false"
                            multiple
                            @input="updateClients"
                            :class="{
                              'form-select--error': $v.queue.clients.$error,
                            }"
                          ></vue-select>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                      <div class="col-xl-6 mb-7">
                        <label :style="$t('clan')=='ar'?'margin-left:280px':''">{{$t('Drivers')}}</label>
                        <div
                          :class="{
                            'form-group--error': $v.queue.drivers.$error,
                          }"
                        >
                          <vue-select
                            name="drivers"
                            label="full_name"
                            :key="'drivers-field'"
                            :options="createData.drivers"
                            :closeOnSelect="false"
                            multiple
                            @input="updateDrivers"
                            :class="{
                              'form-select--error': $v.queue.drivers.$error,
                            }"
                          ></vue-select>
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-6">
                        <div
                          class="form-group"
                          :class="{ 'form-group--error': $v.queue.long.$error }"
                        >
                          <label :style="$t('clan')=='ar'?'float:right':''">{{$t('long')}}</label>
                          <b-form-input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="Location Longitude"
                            :placeholder="$t('long')"
                            :style="$t('clan')=='ar'?'text-align:right':''"
                            value=""
                            v-model="queue.long"
                            :class="{
                              'form-input--error': $v.queue.long.$error,
                            }"
                            :state="
                              $v.queue.long.$error === true ? false : null
                            "
                          />
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div
                          class="form-group"
                          :class="{ 'form-group--error': $v.queue.lat.$error }"
                        >
                          <label :style="$t('clan')=='ar'?'float:right':''">{{$t('lat')}}</label>
                          <b-form-input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            name="state"
                            :placeholder="$t('lat')"
                            :style="$t('clan')=='ar'?'text-align:right':''"
                            v-model="queue.lat"
                            :class="{
                              'form-input--error': $v.queue.lat.$error,
                            }"
                            :state="$v.queue.lat.$error === true ? false : null"
                          />
                          <p class="typo__p" v-if="submitStatus === 'ERROR'" :style="$t('clan')=='ar'?'text-align:right':''">
                        {{$t('feild')}}
                      </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div class="mr-2"></div>
                      <div>
                        <button
                          @click.prevent="submit"
                          class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-submit"
                        >
                          {{$t('Submit')}}
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
    </div>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateQueue",

  data() {
    return {
      queue: {
        name: null,
        drivers: [],
        clients: [],
        long: null,
        lat: null,
      },
      createData: [],
      submitStatus: null,
    };
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Queues", route: "/admin/queues" },
      { title: "New Queue" },
    ]);

    this.createData = await this.$store.dispatch("queues/fetchCreateData");
    //console.log(this.createData)

    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1,
      clickableSteps: true,
    });

    wizard.on("beforeNext", function (/*wizardObj*/) {});

    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    updateClients(value) {
      this.queue.clients = value;
    },
    updateDrivers(value) {
      this.queue.drivers = value;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        await this.$store.dispatch("queues/create", this.queue);

        Swal.fire({
          title: "",
          text: "The queue has been created successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.$router.push({ name: "Queues" });
        this.$store.dispatch("queues/loadAll");

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
  },
  validations: {
    queue: {
      name: {
        required,
      },
      clients: {
        required,
      },
      drivers: {
        required,
      },
      long: {
        required,
      },
      lat: {
        required,
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>